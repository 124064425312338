import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import uploadIcon from '../../assets/images/uploadimg.jpg' 
const leftArrow = '<';
const rightArrow = '>';
var itemsPerPage = 10;
var totalPages = 0;

const AddParts = () =>{
    const [data,setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentItems, setCurrentItems] = React.useState([]);
    let navigate = useNavigate();

    React.useEffect(()=>{
        axios.get('https://backend.viprestaurant.com.pk/auto-parts',{withCredentials:true})
        .then((res)=>{
            setData(res.data);
             // Calculate the range of items to display for the current page
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        // Extract the subset of data for the current page
        const slicedData = res.data.slice(startIndex, endIndex);
    
        // Update the state with the current items for rendering
        setCurrentItems(slicedData);
        totalPages = Math.ceil(res.data.length/itemsPerPage);
        setTimeout(() => {
          document.getElementById('PagesCount').innerHTML= '';
          for (let i = 0; i < totalPages; i++) {
              if (i == 0) {
                  document.getElementById('PagesCount').innerHTML += `<span class="active"> ${i + 1}</span>`
              }else{
                  document.getElementById('PagesCount').innerHTML += `<span> ${i + 1}</span>`
              }
          }
        }, 50);

        })
        .catch((err)=>console.log(err))

    },[])

    function addAutoPart(e) {
        e.preventDefault();
        navigate('/new-auto-part');
        window.location.reload();
    }

    function deleteAutoPart(id) {
        let idData = {id:id};
        axios.post('https://backend.viprestaurant.com.pk/delete-auto-part',idData,{withCredentials:true})
        .then((res)=>{
          setTimeout(() => {
            window.location.reload();
          }, 10);
        })
        .catch((err)=>console.log(err))
    }

    

    React.useEffect(() => {
        // Calculate the range of items to display for the current page
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        // Extract the subset of data for the current page
        const slicedData = data.slice(startIndex, endIndex);
    
        // Update the state with the current items for rendering
        setCurrentItems(slicedData);
      }, [currentPage]);
    
      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        document.getElementById('PagesCount').childNodes.forEach(element => {
            element.classList.remove('active');
         if (element.innerHTML == newPage ) {
            element.classList.add('active');
         }   
        });
      };

      

    return(
        <>
   <div className="page-head">
 <h2 className="text-center">our auto parts</h2>   
 </div>
 <div className="row mt-5 w-80 m-auto">
 <div className='text-left col-2 '>
 <button onClick={addAutoPart} className="w-100 mt- m-auto py-2 contact-form-btn">new auto part<span style={{fontSize:'17px',marginLeft:'0.3rem',fontWeight:'bold'}}>+</span></button>
 </div>
 <div className='col-2'></div>
 <div className='col-2'></div>
 <div className='col-2'></div>
 <div className='col-2'></div>
 <div className="text-center col-2 p-relative">
</div>
  </div>
 <div>
 <div className="row w-70 mt-5 m-auto head-row">
<div className="col-2 order-head-txt">Auto Part</div>
<div className="col-2"></div>
<div className="col-2 order-head-txt">Price</div>
<div className="col-2 order-head-txt">Country</div>
<div className="col-2 order-head-txt">City</div>
<div className="col-2 order-head-txt">action</div>
 </div>
 {
 currentItems.length != 0 ? currentItems.map((d,i)=>{
   return <div className="row w-70 text-left mt-3 m-auto data-row" >
    <div className="col-2 "><div className="w-50 item-img-div m-auto"><img className="w-100" src={d.displayImage} alt=""/></div></div>
    <a href={`/edit-auto-part/${d._id}`} className="col-2 d-flex align-items-end"><p className="item-name">{d.autoPartName}</p></a>
    <div className="col-2 d-flex justify-content-center align-items-end"><p className="item-price">USD: ${d.price}/-</p></div>
    <div className="col-2 d-flex justify-content-center align-items-end "><p className="item-price">{d.country}</p></div>
    <div className="col-2 d-flex justify-content-center align-items-end"><p className="item-price">{d.city}</p></div>
    <div  className="col-2 d-flex justify-content-center align-items-center"><button className="item-remove mt-3" onClick={(e)=>deleteAutoPart(d._id)}><sub className="mt-3">*</sub><span style={{marginLeft:'0.5vw'}}>Delete</span></button></div>
 </div>
  }):(<div className="text-center my-5 py-5" >No Data Found</div>)

}
<div style={{textAlign: 'right',width: '90%',marginTop: '5vw'}}>
        <button     className={`${currentPage === 1?'':'active'} pagesBtn`} onClick={() => handlePageChange(currentPage - 1)} 
        disabled={currentPage === 1}>
        {leftArrow}
        </button>
        <div id='PagesCount' style={{display:'inline-block'}}>
        
        </div>
        <button
        className={`${currentPage === Math.ceil(data.length / itemsPerPage)?'':'active'} pagesBtn`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        >
          {rightArrow}
        </button>
      </div>
 </div>
</>)}



export default AddParts;