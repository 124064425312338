import axios from "axios";
import React from "react";
import LoadingSpinner from '../../component/LoadingSpinner.js';
const leftArrow = '<';
const rightArrow = '>';

var itemsPerPage = 10;
var totalPages = 0;


const FOB = () =>{
    const [data,setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentItems, setCurrentItems] = React.useState([]);
    var [isLoading,setIsLoading] = React.useState(false);
    var [countryFlag,setCountryFlag] = React.useState(false);
    var [newM3Price, setNewM3Price] = React.useState({
        m3Price:'',country:''
  })
  
  
  function addNewM3Price(e) {
      e.preventDefault();
    if (!countryFlag) {
      setIsLoading(true);
        if (newM3Price.m3Price && newM3Price.country  ) { 
           setTimeout(() => {    
             axios.post('https://backend.viprestaurant.com.pk/add-m3-price',newM3Price,{withCredentials:true})
              .then((res)=>{
                if (res.status == 200) {            
                  setIsLoading(false);
                  window.location.reload();
                }
              })
              .catch((err)=>{
                setIsLoading(false);
              })
           }, 100);
        }else{
          setIsLoading(false);
          document.getElementById('newproductUpdateTxt').innerText = 'kindly fill the form properly...';
          setTimeout(() => {
            document.getElementById('newproductUpdateTxt').innerText = '';
          }, 2500);
        }
    }else{
    document.getElementById('newproductUpdateTxt').innerText = 'M3 Price for this country is already added...';
    setTimeout(() => {
      document.getElementById('newproductUpdateTxt').innerText = '';
    }, 2500);
  }
}
      



  React.useEffect(()=>{
      axios.get('https://backend.viprestaurant.com.pk/m3price',{withCredentials:true})
      .then((res)=>{
          setData(res.data);
           // Calculate the range of items to display for the current page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
  
      // Extract the subset of data for the current page
      const slicedData = res.data.slice(startIndex, endIndex);
  
      // Update the state with the current items for rendering
      setCurrentItems(slicedData);
      totalPages = Math.ceil(res.data.length/itemsPerPage);
      setTimeout(() => {
        document.getElementById('PagesCount').innerHTML= '';
        for (let i = 0; i < totalPages; i++) {
            if (i == 0) {
                document.getElementById('PagesCount').innerHTML += `<span class="active"> ${i + 1}</span>`
            }else{
                document.getElementById('PagesCount').innerHTML += `<span> ${i + 1}</span>`
            }
        }
      }, 50);

      })
      .catch((err)=>console.log(err))

  },[])

 

  function deleteProduct(id) {
      let idData = {id:id};
      axios.post('https://backend.viprestaurant.com.pk/delete-m3-price',idData,{withCredentials:true})
      .then((res)=>{
          window.location.reload();
      })
      .catch((err)=>console.log(err))
  }

  

  React.useEffect(() => {
      // Calculate the range of items to display for the current page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
  
      // Extract the subset of data for the current page
      const slicedData = data.slice(startIndex, endIndex);
  
      // Update the state with the current items for rendering
      setCurrentItems(slicedData);
    }, [currentPage]);
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
      document.getElementById('PagesCount').childNodes.forEach(element => {
          element.classList.remove('active');
       if (element.innerHTML == newPage ) {
          element.classList.add('active');
       }   
      });
    };

  const handleChange =(e) =>{
    for (let i = 0; i < data.length; i++) {
      if (data[i].country == e.target.value) {
          setCountryFlag(true);
          break;
      }else{
          setCountryFlag(false);
      }
    }
    setNewM3Price({...newM3Price,country:e.target.value})
  }
      return(<>
      { isLoading ? (<LoadingSpinner/>):(
        <div><div className="page-head">
   <h2 className="text-center">Add M3 Price</h2>   
   </div>
      <div className="row w-95 m-auto mt-5">
        <div className="col-5 text-left mb-3" >
      
      <label className="checkout-field-label d-block" for="price">Country<span className="important-star">*</span></label>
      <select name="tp_country_id" style={{height:'36px',border: '1px solid #d4d4d4',width:'95%'}} value={newM3Price.country}  onChange={handleChange}  id="tp_country_id" autocomplete="off" >
                                          <option value="">select</option>
                                          <option value="Zambia">Zambia</option>
  <option value="D.R.Congo">D.R.Congo</option>
  <option value="Mozambique">Mozambique</option>
  <option value="Zimbabwe">Zimbabwe</option>
  <option value="Uganda">Uganda</option>
  <option value="Tanzania">Tanzania</option>
  <option value="Malawi">Malawi</option>
  <option value="Burundi">Burundi</option>
  <option value="Lesotho">Lesotho</option>
  <option value="South Sudan">South Sudan</option>
  <option value="Botswana">Botswana</option>
  <option value="Kenya">Kenya</option>
  <option value="Republic of South Africa">Republic of South Africa</option>
  <option value="Djibouti">Djibouti</option>
  <option value="Ethiopia">Ethiopia</option>
  <option value="Angola">Angola</option>
  <option value="Benin">Benin</option>
  <option value="Cameroon">Cameroon</option>
  <option value="Gabon">Gabon</option>
  <option value="Ghana">Ghana</option>
  <option value="Guinea">Guinea</option>
  <option value="Madagascar">Madagascar</option>
  <option value="Mali">Mali</option>
  <option value="Namibia">Namibia</option>
  <option value="Nigeria">Nigeria</option>
  <option value="Rwanda">Rwanda</option>
  <option value="Sierra Leone">Sierra Leone</option>
  <option value="Sudan">Sudan</option>
  <option value="Algeria">Algeria</option>
  <option value="Canary Islands">Canary Islands</option>
  <option value="Cape Verde">Cape Verde</option>
  <option value="Central African Republic">Central African Republic</option>
  <option value="Chad">Chad</option>
  <option value="Equatorial Guinea">Equatorial Guinea</option>
  <option value="Eritrea">Eritrea</option>
  <option value="Gambia">Gambia</option>
  <option value="Liberia">Liberia</option>
  <option value="Mahe">Mahe</option>
  <option value="Maldives">Maldives</option>
  <option value="Mauritania">Mauritania</option>
  <option value="Mayotte">Mayotte</option>
  <option value="Morocco">Morocco</option>
  <option value="Niger">Niger</option>
  <option value="Republic of Guinea-Bissau">Republic of Guinea-Bissau</option>
  <option value="Republic of the Congo">Republic of the Congo</option>
  <option value="Reunion">Reunion</option>
  <option value="Saint Helena">Saint Helena</option>
  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
  <option value="Seychelles">Seychelles</option>
  <option value="Swaziland">Swaziland</option>
  <option value="Tunisia">Tunisia</option>
  <option value="Afghanistan">Afghanistan</option>
  <option value="Albania">Albania</option>
  <option value="American Samoa">American Samoa</option>
  <option value="Anguilla">Anguilla</option>
  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
  <option value="Argentina">Argentina</option>
  <option value="Armenia">Armenia</option>
  <option value="Aruba">Aruba</option>
  <option value="Australia">Australia</option>
  <option value="Austria">Austria</option>
  <option value="Azerbaijan">Azerbaijan</option>
  <option value="Bahamas">Bahamas</option>
  <option value="Bahrain">Bahrain</option>
  <option value="Bangladesh">Bangladesh</option>
  <option value="Barbados">Barbados</option>
  <option value="Belgium">Belgium</option>
  <option value="Belize">Belize</option>
  <option value="Bermuda">Bermuda</option>
  <option value="Bhutan">Bhutan</option>
  <option value="Bolivia">Bolivia</option>
  <option value="Bonaire/Netherlands Antilles">Bonaire/Netherlands Antilles</option>
  <option value="Brazil">Brazil</option>
  <option value="British Virgin Islands">British Virgin Islands</option>
  <option value="Brunei Darussalam">Brunei Darussalam</option>
  <option value="Bulgaria">Bulgaria</option>
  <option value="Burkina Faso">Burkina Faso</option>
  <option value="Cambodia">Cambodia</option>
  <option value="Canada">Canada</option>
  <option value="Cayman Islands">Cayman Islands</option>
  <option value="Chile">Chile</option>
  <option value="Colombia">Colombia</option>
  <option value="Commonwealth of Dominica">Commonwealth of Dominica</option>
  <option value="Comoros">Comoros</option>
  <option value="Cook Islands">Cook Islands</option>
  <option value="Costa Rica">Costa Rica</option>
  <option value="Cote D'Ivoire">Cote D'Ivoire</option>
  <option value="Croatia">Croatia</option>
  <option value="Cuba">Cuba</option>
  <option value="Curacao/Netherlands Antilles">Curacao/Netherlands Antilles</option>
  <option value="Cyprus">Cyprus</option>
  <option value="Czech republic">Czech republic</option>
  <option value="Denmark">Denmark</option>
  <option value="Dominican Republic">Dominican Republic</option>
  <option value="East Timor">East Timor</option>
  <option value="Ecuador">Ecuador</option>
  <option value="Egypt">Egypt</option>
  <option value="Estonia">Estonia</option>
  <option value="Fiji">Fiji</option>
  <option value="Finland">Finland</option>
  <option value="France">France</option>
  <option value="French Guiana">French Guiana</option>
  <option value="French Polynesia">French Polynesia</option>
  <option value="Georgia">Georgia</option>
  <option value="Germany">Germany</option>
  <option value="Greece">Greece</option>
  <option value="Grenada">Grenada</option>
  <option value="Guadeloupe">Guadeloupe</option>
  <option value="Guatemala">Guatemala</option>
  <option value="Guyana">Guyana</option>
  <option value="Haiti">Haiti</option>
  <option value="Honduras">Honduras</option>
  <option value="Hong Kong">Hong Kong</option>
  <option value="Hungary">Hungary</option>
  <option value="Iceland">Iceland</option>
  <option value="India">India</option>
  <option value="Indonesia">Indonesia</option>
  <option value="Iran">Iran</option>
  <option value="Iraq">Iraq</option>
  <option value="Ireland">Ireland</option>
  <option value="Israel">Israel</option>
  <option value="Italy">Italy</option>
  <option value="Jamaica">Jamaica</option>
  <option value="Japan">Japan</option>
  <option value="Jordan">Jordan</option>
  <option value="Kazakhstan">Kazakhstan</option>
  <option value="Kiribati">Kiribati</option>
  <option value="Korea">Korea</option>
  <option value="Kuwait">Kuwait</option>
  <option value="Kyrgyzstan">Kyrgyzstan</option>
  <option value="LAO PDR">LAO PDR</option>
  <option value="Latvia">Latvia</option>
  <option value="Lebanon">Lebanon</option>
  <option value="Libya">Libya</option>
  <option value="Lithuania">Lithuania</option>
  <option value="Luxembourg">Luxembourg</option>
  <option value="Macau">Macau</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Marshall Islands">Marshall Islands</option>
  <option value="Martinique">Martinique</option>
  <option value="Mauritius">Mauritius</option>
  <option value="Mexico">Mexico</option>
  <option value="Micronesia">Micronesia</option>
  <option value="MOLDOVA">MOLDOVA</option>
  <option value="Mongolia">Mongolia</option>
  <option value="Montenegro">Montenegro</option>
  <option value="Montserrat">Montserrat</option>
  <option value="Myanmar">Myanmar</option>
  <option value="Nauru">Nauru</option>
  <option value="Nepal">Nepal</option>
  <option value="Netherlands">Netherlands</option>
  <option value="Netherlands Antilles">Netherlands Antilles</option>
  <option value="New Caledonia">New Caledonia</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Niue">Niue</option>
  <option value="Norway">Norway</option>
  <option value="Oman">Oman</option>
  <option value="Pakistan">Pakistan</option>
  <option value="Palau">Palau</option>
  <option value="Panama">Panama</option>
  <option value="Papua New Guinea">Papua New Guinea</option>
  <option value="Paraguay">Paraguay</option>
  <option value="Peoples Republic of China">Peoples Republic of China</option>
  <option value="Peru">Peru</option>
  <option value="Philippines">Philippines</option>
  <option value="Poland">Poland</option>
  <option value="Portugal">Portugal</option>
  <option value="PUERTO RICO">PUERTO RICO</option>
  <option value="Qatar">Qatar</option>
  <option value="REPUBLIC OF BELARUS">REPUBLIC OF BELARUS</option>
  <option value="Republic of El Salvador">Republic of El Salvador</option>
  <option value="Republic of Macedonia">Republic of Macedonia</option>
  <option value="Republic of Nicaragua">Republic of Nicaragua</option>
  <option value="Romania">Romania</option>
  <option value="Russian Federation">Russian Federation</option>
  <option value="Saba Island">Saba Island</option>
  <option value="Saint Eustatius">Saint Eustatius </option>
  <option value="Saint Kitts And Nevis">Saint Kitts And Nevis</option>
  <option value="Samoa">Samoa</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="Senegal">Senegal</option>
  <option value="Serbia">Serbia</option>
  <option value="Singapore">Singapore</option>
  <option value="Slovak Republic">Slovak Republic</option>
  <option value="SLOVENIA">SLOVENIA</option>
  <option value="Solomon Islands">Solomon Islands</option>
  <option value="Somalia">Somalia</option>
  <option value="Spain">Spain</option>
  <option value="Sri Lanka">Sri Lanka</option>
  <option value="St.Barthelemy">St.Barthelemy</option>
  <option value="St.Croix">St.Croix</option>
  <option value="t.Lucia">St.Lucia</option>
  <option value="St.Maarte">St.Maarten</option>
  <option value="St.Thomas">St.Thomas</option>
  <option value="St.Vincent">St.Vincent</option>
  <option value="Suriname">Suriname</option>
  <option value="Sweden">Sweden</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Syria">Syria</option>
  <option value="TAIWAN">TAIWAN</option>
  <option value="Tajikistan">Tajikistan</option>
  <option value="Thailand">Thailand</option>
  <option value="Togo">Togo</option>
  <option value="Tonga">Tonga</option>
  <option value="Tortola">Tortola</option>
  <option value="Trinidad And Tobago">Trinidad And Tobago</option>
  <option value="Turkey">Turkey</option>
  <option value="Turkmenistan">Turkmenistan</option>
  <option value="Turks And Caicos Islands">Turks And Caicos Islands</option>
  <option value="Tuvalu">Tuvalu</option>
  <option value="UAE">UAE</option>
  <option value="Ukraine">Ukraine</option>
  <option value="United Kingdom">United Kingdom</option>
  <option value="Uruguay">Uruguay</option>
  <option value="US VIRGIN ISLANDS">US VIRGIN ISLANDS</option>
  <option value="USA">USA</option>
  <option value="Uzbekistan">Uzbekistan</option>
  <option value="Vanuatu">Vanuatu</option>
  <option value="Venezuela">Venezuela</option>
  <option value="Vietnam">Vietnam</option>
  <option value="Wallis et Futuna">Wallis et Futuna</option>
  <option value="Yemen">Yemen</option>
  <option value="Somaliland">Somaliland</option>
  
                                      </select>
                                      </div>
    <div className="col-5 text-left mb-3">
      <label className="checkout-field-label d-block" for="M3 Price">M3 Price (in dollar  $)<span className="important-star">*</span></label>
      <input type="number" className="checkout-input-field w-95" placeholder="Enter m3 price in $" value={newM3Price.m3Price} onChange={(e)=>setNewM3Price({...newM3Price,m3Price:e.target.value})}   name="M3 Price" />
      </div>
    
      <div className='text-center  col-2 mb-3' style={{marginTop:'17px'}}>
 <button  className="w-100 mt- m-auto py-2 contact-form-btn" onClick={addNewM3Price}>add</button>
    <p className='text-center alert-txt mb-3'  style={{color:'red',marginBottom:'0',marginTop:'1rem',height:'60px'}} id='newproductUpdateTxt'></p>
 </div>
      </div>
      <div>
 <div className="row w-70 mt-5 m-auto head-row">
<div className="col-2 order-head-txt">Country</div>
<div className="col-2 order-head-txt">M3 Price $</div>
<div className="col-2 order-head-txt"></div>
<div className="col-2 order-head-txt"></div>
<div className="col-2 order-head-txt">Delete</div>
 </div>
 {
 currentItems.length != 0 ? currentItems.map((d,i)=>{
   return <div className="row w-70 text-left mt-3 m-auto data-row" >
     <div className="col-2 d-flex justify-content-center align-items-end"><p className="item-price">{d.country}</p></div>
     <div className="col-2 d-flex justify-content-center align-items-end"><p className="item-price">{d.m3Price}/-</p></div>
    <div className="col-2 d-flex justify-content-center align-items-end"></div>
    <div  className="col-2 d-flex justify-content-center align-items-center"></div>
    <div  className="col-2 d-flex justify-content-center align-items-center"><button className="item-remove mt-3" onClick={(e)=>deleteProduct(d._id)}><sub className="mt-3">*</sub><span style={{marginLeft:'0.5vw'}}>Delete</span></button></div>
 </div>
  }):(<div className="text-center my-5 py-5" > No Record Found</div>)

}
<div style={{textAlign: 'right',width: '90%',marginTop: '5vw'}}>
        <button     className={`${currentPage === 1?'':'active'} pagesBtn`} onClick={() => handlePageChange(currentPage - 1)} 
        disabled={currentPage === 1}>
        {leftArrow}
        </button>
        <div id='PagesCount' style={{display:'inline-block'}}>
        
        </div>
        <button
        className={`${currentPage === Math.ceil(data.length / itemsPerPage)?'':'active'} pagesBtn`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        >
          {rightArrow}
        </button>
      </div>
 </div>
   </div>)}
      </>)
}



export default FOB;